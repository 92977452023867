import React        from "react";
import { graphql }  from "gatsby"
import { Helmet }   from "react-helmet"

import Layout   from '../components/layout';

import StageZukunft          from '../components/molecules/stageZukunft/stageZukunft';
import StageTeam             from '../components/molecules/stageTeam/stageTeam';
import StageKontakt          from '../components/molecules/stageContact/stageContact';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Helmet
      htmlAttributes={{
        lang: 'de'
      }}
    >
      <meta charSet="utf-8" />
      <title>Über Uns - ChancenCheckin</title>
      <meta name="description" content="Wir sind ChancenCheckin –  vielfältige Möglichkeiten für Deine unternehmerische Herausforderung. Schnell. Einfach. Individuell." />
      <link rel="canonical" href="https://www.chancencheckin.de/ueber-uns" />
    </Helmet>

    <StageZukunft
      imgHackathon={data.imgHackathon.childImageSharp.fluid}
      imgPostHackathon={data.imgPostHackathon.childImageSharp.fluid}
      imgZukunft={data.imgZukunft.childImageSharp.fluid}
      imgTimeline={data.imgTimeline.childImageSharp.fluid}
    />

    <StageTeam />

    <StageKontakt />
  </Layout>
)

export const futureImage = graphql`
  fragment futureImage on File {
    childImageSharp {
      fluid(maxWidth: 150) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    imgHackathon: file(relativePath: { eq: "hackathon.png" }) {
      ...futureImage
    },
    imgPostHackathon: file(relativePath: { eq: "postHackathon.png" }) {
      ...futureImage
    },
    imgZukunft: file(relativePath: { eq: "zukunft.png" }) {
      ...futureImage
    },
    imgTimeline: file(relativePath: { eq: "arrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
