import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';

import styles from './card.module.css';

const TeamGrid = ({member, image, columns, className }) => {

  const memberImage = () => {
    if (image)
      return (
        <Img
          className={styles.image}
          fluid={image.node.childImageSharp.fluid}
          title={member.name}
          alt={member.name}
        />
      )
    else
      return <div className={styles.placeholder}></div>
  }

  return (
    <div className={styles.card}>
      {memberImage()}
      <div className={styles.name}>{member.name}</div>
    </div>
  )
}

TeamGrid.propTypes = {
  member: PropTypes.object,
  image: PropTypes.object,
  columns: PropTypes.string,
  className: PropTypes.string
}

TeamGrid.defaultProps = {
  member: {},
  image: null,
  columns: '3',
  className: ''
}

export default TeamGrid;
