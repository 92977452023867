import React from 'react';

import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Devider  from '../../atoms/devider/devider';
import Text     from '../../atoms/text/text';
import TeamGrid from '../teamGrid/teamGrid';
import Stage    from '../stage/stage';


import styles from './stageTeam.module.css';
import TeamData from "../../../data/team.json";


const StageTeam = () => (
  <Stage anchor="team" className={styles.stage}>
    <Headline tag="h2" visualStyle="h1">Das ist unser Team</Headline>
    <Slugline tag="h1">So vielfältig wie Du</Slugline>
    <Devider />
    <Text align="center">Unter der Rubrik alternative Geschäftsmodelle hat sich unser diverses Team neu zusammengefunden und es begannen 48 Stunden des selbst Organisierens, Diskutierens, Entwickelns, Textens und Video schneidens. In dieser kurzen Zeit ging es darum, von der Idee zum Prototypen unserer Webseite zu kommen. Einen ersten Einblick in unsere Arbeit seht Ihr in unserem ersten Pitchvideo im #wirvsvirushack (Stand Hackathonende).</Text>
    <TeamGrid columns="3" teamData={TeamData} />
  </Stage>
)
export default StageTeam;
