import React from 'react';
import Img from "gatsby-image"

import Grid     from '../grid/grid';
import Card     from '../card/card';
import Stage    from '../stage/stage';
import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Devider from '../../atoms/devider/devider';
import Text     from '../../atoms/text/text';

import styles from './stageZukunft.module.css';

const StageZukunft = ({imgHackathon, imgPostHackathon, imgZukunft, imgTimeline}) => (
  <Stage anchor="zukunft" styleFull="light">
    <Headline tag="h2" visualStyle="h1">Unsere Geschichte</Headline>
    <Slugline tag="h2">Vom Hackathon zur Vision</Slugline>
    <Devider />
    <Grid>
      <Card className={styles.card}>
        <Headline className={styles.headline} tag="h3">Hackathon</Headline>
        <Img className={styles.image} fluid={imgHackathon} alt="Hackathon" />
        <Text align="center">
        Ab dem 20.03.2020 wurden während des von der Bundesregierung und einigen Start – Ups ausgerufenen #WirVsVirus Hackathons 1.500 Lösungen für Herausforderungen, die durch das Corona-Virus entstanden sind, entwickelt.
        </Text>
      </Card>
      <Card className={styles.card}>
        <Headline className={styles.headline} tag="h3">Post Hackathon</Headline>
        <Img className={styles.image} fluid={imgPostHackathon} alt="Hackathon" />
        <Text align="center">
          Für uns war klar: es geht weiter! Unser Team traf sich in vielen Videocalls: Visionsworkshop, Businessmodelling, Interviews mit Unternehmern und eine neue Landingpage. Wir brachten unsere Social Media Kanäle auf Vordermann und waren auch das Titelbild der #wirvsvirushack-Geschichte in der Berliner Zeitung.
        </Text>
      </Card>
      <Card className={styles.card}>
        <Headline className={styles.headline} tag="h3">Zukunftsvision</Headline>
        <Img className={styles.image} fluid={imgZukunft} alt="Hackathon" />
        <Text align="center">
          Ganz nach unserer Vision "Wir inspirieren und unterstützen Menschen unternehmerisch neu zu denken, um Chancen zu ergreifen und selbstbestimmt zu agieren." möchten wir Menschen bei der Entwicklung oder Umorientierung zu einem neuen Geschäftsmodell verhelfen.
        </Text>
      </Card>
    </Grid>
    <Img className={styles.imgArrow} fluid={imgTimeline} alt="Timeline" />
  </Stage>
)

export default StageZukunft;
