import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Card from './card/card';

import styles from './teamGrid.module.css'

const TeamGrid = ({teamData, columns, className }) => (
  <StaticQuery
    query={graphql`
      query MemberImageQuery {
        images: allFile(filter: {relativeDirectory: {eq: "team"}}) {
          edges {
            node {
              base
              childImageSharp {
                fluid(maxWidth: 265) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
        <div className={[styles.teamGrid, className].join(' ')} style={{'--cols': columns}}>
          { teamData.map((member, index) => (
            <Card
              key={index}
              member={member}
              image={
                data.images.edges.find(n => n.node.base === member.image)}
            />
          ))}
        </div>
      )
    }
  />
)

TeamGrid.propTypes = {
  teamData: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.string,
  className: PropTypes.string
}

TeamGrid.defaultProps = {
  teamData: [],
  columns: '3',
  className: ''
}

export default TeamGrid;
